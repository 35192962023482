.about-section {
  max-width: var(--containerSize);
  width: 100%;
  height: 100vh;
  padding-top: 80px;
  /* box-shadow: 0px 0.2px var(--titleColor); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 80px;
}

.about-content {
  display: flex;
  flex-direction: column;
}

.about-text {
  padding-bottom: 20px;
}

.about-text h1 {
  font-size: 45px;
  font-weight: 600;
  color: var(--titleColor);
  padding-bottom: 20px;
}

.about-text p {
  font-size: 22px;
  font-weight: 400;
  color: var(--textColor);
}

.about-contact {
  display: flex;
  gap: 12px;
  padding-bottom: 20px;
}

.about-contact a {
  height: 40px;
  width: 40px;
  font-size: 25px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: solid 1px;
  border-radius: 50%;
  border-color: var(--titleColor);
  background-color: var(--templateColor);
  outline: none;
  cursor: pointer;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-contact a:hover {
  transform: scale(1.1);
}

.about-image {
}

img {
  height: 500px;
  border-radius: 1%;
}

@media screen and (max-width: 1280px) {
  .about-section {
    max-width: 900px;
    gap: 28px;
  }
  .about-text h1 {
    font-size: 30px;
  }

  .about-text p {
    font-size: 20px;
    font-weight: 400;
  }

  img {
    height: 400px;
  }
}

@media screen and (max-width: 900px) {
  .about-section {
    max-width: 700px;
    width: 100%;
    gap: 28px;
  }

  .about-text h1 {
    font-size: 28px;
    padding-bottom: 10px;
  }

  .about-text p {
    font-size: 18px;
  }

  .about-contact a {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }

  img {
    height: 320px;
  }
}

@media screen and (max-width: 770px) {
  .about-section {
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-container {
    flex-direction: column-reverse;
    gap: 15px;
  }
  .about-content {
    width: 400px;
    display: flex;
    flex-direction: column;
  }

  .about-text h1 {
    font-size: 30px;
    text-align: center;
  }

  .about-text p {
    font-size: 19px;
    text-align: center;
  }

  .about-contact {
    justify-content: center;
  }
  .about-contact a {
    height: 35px;
    width: 35px;
    font-size: 22px;
  }

  img {
    height: 300px;
  }
}

@media screen and (max-width: 480px) {
  .about-section {
    max-width: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-container {
    flex-direction: column-reverse;
    gap: 10px;
  }
  .about-content {
    width: 300px;
    display: flex;
    flex-direction: column;
  }

  .about-text h1 {
    font-size: 26px;
    text-align: center;
    padding-bottom: 8px;
  }

  .about-text p {
    font-size: 17px;
    text-align: center;
  }

  .about-contact {
    justify-content: center;
  }
  .about-contact a {
    height: 30px;
    width: 30px;
    font-size: 18px;
  }

  img {
    height: 280px;
  }
}
