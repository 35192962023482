.swiper {
  width: 100%;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide {
  height: 500px;
  width: 300px;
  border: 1px solid var(--titleColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: var(--bg);
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--titleColor);
  z-index: 10;
}

.swiper-pagination-bullet-active {
  background-color: var(--titleColor);
}

@media screen and (max-width: 1280px) {
  .swiper {
    width: 800px;
    height: 500px;
    padding: auto;
  }

  .swiper-slide {
    height: 450px;
    width: 800px;
  }
}

@media screen and (max-width: 900px) {
  .swiper {
    width: 700px;
    height: 450px;
    padding: auto;
  }

  .swiper-slide {
    height: 400px;
    width: 700px;
  }
}

@media screen and (max-width: 770px) {
  .swiper {
    width: 400px;
    height: 500px;
    padding: auto;
  }

  .swiper-slide {
    height: 450px;
    width: 400px;
  }
}

@media screen and (max-width: 480px) {
  .swiper {
    width: 300px;
    height: 420px;
    padding: auto;
  }

  .swiper-slide {
    height: 380px;
    width: 300px;
  }
}
