@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap");

:root {
  --bg: #d8dcd2;
  --titleColor: #96755d;
  --textColor: #000000;
  --templateColor: #c4a19b;
  --containerSize: 1250px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Cormorant Garamond", serif;
}

.App {
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.appContainer {
  width: 100%;
  max-width: var(--containerSize);
}
