.navbar-section {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: var(--bg);
  padding: 0 16px;
  box-shadow: 0px 0.2px var(--titleColor);
}

.navbar {
  max-width: var(--containerSize);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.logo {
  width: max-content;
  cursor: pointer;
}

.logo h3 {
  color: var(--titleColor);
  font-size: 35px;
  font-weight: 700;
}

.navList {
  display: flex;
  list-style: none;
  color: var(--textColor);
  gap: 50px;
}

.navList li {
  font-size: 23px;
  font-weight: 600;
  color: var(--titleColor);
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .navbar-section {
    height: 70px;
  }

  .navbar {
    max-width: 900px;
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    .navbar-section {
      height: 70px;
    }

    .navbar {
      max-width: 700px;
      width: 100%;
    }
  }
  .logo h3 {
    font-size: 30px;
  }
  .navList li {
    font-size: 22px;
  }
}

@media screen and (max-width: 770px) {
  .navbar-section {
    height: 70px;
  }

  .navbar {
    max-width: 500px;
    width: 100%;
  }

  .logo h3 {
    font-size: 28px;
  }
  .navList li {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .navbar-section {
    height: 60px;
  }

  .navbar {
    max-width: 400px;
    width: 100%;
  }

  .logo h3 {
    font-size: 26px;
  }
  .navList li {
    font-size: 18px;
  }
}
